<template>
  <a-spin :spinning="isLoading">
    <sdPageHeader title="Müşteri Hareketleri" />
    <Main>
      <a-row
        type="flex"
        justify="center"
        :gutter="16"
        align="top"
      >
        <a-col :span="24">
          <sdCards headless>
            <div class="pst">
              <div class="subMenu>">
                <span class="blck">
                  <sdFeatherIcons
                    type="paperclip"
                    class="mr-x"
                  />
                  <b class="d-sm-block">Fatura No</b></span> {{ $route.params.invoice_id }}
              </div>
              <div class="subMenu>">
                <span class="blck">
                  <sdFeatherIcons
                    type="calendar"
                    class="mr-x"
                  />
                  <b>Fatura Tarihi</b></span> {{ details.receiptDate }}
              </div>
              <div class="subMenu>">
                <span class="blck">
                  <sdFeatherIcons
                    type="airplay"
                    class="mr-x"
                  />
                  <b>Kargo Fatura Tutarı</b></span> {{ details.totalCompanyBasePrice }}
              </div>
              <div class="subMenu>">
                <span class="blck">
                  <sdFeatherIcons
                    type="pie-chart"
                    class="mr-x"
                  />
                  <b>Kargo Fiyat Toplamı(+KDV)</b></span> {{ details.totalCompanyPrice }}
              </div>
              <div class="subMenu>">
                <span class="blck">
                  <sdFeatherIcons
                    type="layers"
                    class="mr-x"
                  />
                  <b>Hesaplanan Fiyat Toplamı</b></span> {{ details.totalCargongPrice }}
              </div>
              <div class="subMenu>">
                <span class="blck">
                  <sdFeatherIcons
                    type="bar-chart"
                    class="mr-x"
                  />
                  <b>Fiyat Farkı</b></span>
                <span
                  v-if="details.totalDiff>0"
                  style="color: green;font-weight: bold"
                >
                  {{ details.totalDiff }} TL
                </span>
                <span
                  v-else-if="details.totalDiff===0"
                  style="color: red;font-weight: bold"
                >
                  {{ details.totalDiff }} TL
                </span>
                <span
                  v-else
                  style="color: red;font-weight: bold"
                >
                  {{ details.totalDiff }} TL
                </span>
              </div>
            </div>
            <hr>
            <div class="pst-filter">
              <span class="pddng">
                <a-checkbox v-model:checked="only_show_diff">
                  Sadece Fiyat Farkı Olanları Göster
                </a-checkbox>
              </span>
            </div>

            <a-table
              :data-source="tableItems"
              :columns="columns"
              row-key="id"
            >
              <template #is_returned="{record}">
                <div v-if="record.is_returned">
                  <sdFeatherIcons type="trending-down" />
                  İade
                </div>
                <div v-else>
                  -
                </div>
              </template>
              <template #price_diff="{record}">
                <div
                  v-if="record.price_diff>0"
                  style="color: #198754"
                >
                  <b>+{{ record.price_diff }}</b>
                </div>
                <div
                  v-else-if="record.price_diff<0"
                  style="color: #fd7e14"
                >
                  <b>{{ record.price_diff }}</b>
                </div>
                <div v-else>
                  -
                </div>
              </template>
            </a-table>
          </sdCards>
        </a-col>
      </a-row>
    </Main>
  </a-spin>
</template>

<script>

import { Main } from '@/view/styled'

export default {
  components: {
    Main,
  },
  data() {
    return {
      rawItems: [],
      isLoading: true,
      only_show_diff: false,
      details: {
        totalCompanyPrice: 0,
        totalCargongPrice: 0,
        totalDiff: 0,
      },
      columns: [
        {
          title: 'Sipariş Numarası',
          dataIndex: 'request_number',
          align: 'center',
        },
        {
          title: 'Fatura Ücreti (KDV Hariç)',
          dataIndex: 'price_of_company',
          align: 'center',
        },
        {
          title: 'Hesaplanan Ücret (KDV Hariç)',
          dataIndex: 'price_of_cargong',
          align: 'center',
        },
        {
          title: 'Fiyat Farkı',
          dataIndex: 'price_diff',
          align: 'center',
          slots: {
            customRender: 'price_diff',
          },
        },
        {
          title: 'İade mi?',
          dataIndex: 'is_returned',
          align: 'center',
          slots: {
            customRender: 'is_returned',
          },
        },
      ],
    }
  },
  computed: {
    tableItems() {
      if (this.only_show_diff) {
        return this.rawItems.filter(e => parseFloat(e.price_diff) !== 0)
      }
      return this.rawItems
    },
  },
  mounted() {
    this.isLoading = true
    this.$restMethods.postForNoc('/get-customer-selected-detail', {
      invoice_id: this.$route.params.invoice_id,
      customer_id: this.$route.params.customer_id,
    }).then(resposne => {
      if ( ! resposne.hasError()) {
        const data = resposne.getData()

        this.rawItems = data.items

        this.details.totalCompanyBasePrice = data.details.totalCompanyBasePrice
        this.details.totalCargongPrice = data.details.totalCargongPrice
        this.details.totalCompanyPrice = data.details.totalCompanyPrice
        this.details.totalDiff = data.details.totalDiff
        this.details.receiptDate = data.details.receiptDate
      }
      this.isLoading = false
    })
  },
}
</script>

<style>

hr {
  border-color: #ededed;
  border-top: none;
  border-left: none;
  border-right: none;
}

.pst {
  display: flex;
  justify-content: space-between; /* öğeler arasında eşit boşluk bırakır */
  align-items: center;
  padding: 0 20px; /* Sağdan ve soldan biraz boşluk eklemek için, dilerseniz bu değeri değiştirebilirsiniz */
}

.pst > div {
  flex: 1; /* Her bir öğe için eşit genişlik alır */
  text-align: center; /* İçerikleri merkezler */
}

.sub {
  display: block;
}

.subMenu {
  display: inline-block;
  margin-right: 20px; /* Optional: This gives space between items */
  vertical-align: top; /* This makes sure the items are aligned to the top */
}

.pst {
  font-size: 12pt;
  display: flex;
  justify-content: space-between; /* öğeler arasında eşit boşluk bırakır */
  align-items: center;
  padding: 0 20px; /* Sağdan ve soldan biraz boşluk eklemek için, dilerseniz bu değeri değiştirebilirsiniz */
  margin-bottom: 10px;
}

.pst > div {
  flex: 1; /* Her bir öğe için eşit genişlik alır */
  text-align: center; /* İçerikleri merkezler */
}

.pstCol {

  font-size: 13pt;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  padding: 0 20px;
  flex-wrap: nowrap;
  flex-direction: column;
}

.pstCol > div {
  color: #5A5F7D !important;
  flex: 1; /* Her bir öğe için eşit genişlik alır */
  text-align: center; /* İçerikleri merkezler */
}

.pst-filter {
  flex-direction: row-reverse;
  font-size: 12pt;
  display: flex;
  justify-content: flex-start; /* öğeler arasında eşit boşluk bırakır */
  align-items: center;
  padding: 0 20px; /* Sağdan ve soldan biraz boşluk eklemek için, dilerseniz bu değeri değiştirebilirsiniz */
  margin-bottom: 10px;
}

.pst-filter > div {
  flex: 1; /* Her bir öğe için eşit genişlik alır */
  text-align: center; /* İçerikleri merkezler */
}

.mr-x {
  margin-right: 10px;
}

.blck {
  display: block;
}

.pddng {
  padding: 0 10px;
}


</style>
